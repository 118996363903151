<template>
  <v-dialog
    v-model="show"
  >
    <v-container
      class="microtrend-overlay"
      v-if="microtrend"
      :key="microtrend.id"
    >
      <v-row
        no-gutters
      >
        <v-col
          cols="12"
          xs="12"
          sm="8"
        >
          <v-card
            flat
          >
            <v-img
              :src="microtrend.image"
              height="400"
            />
            <v-card-title>
              <h2>{{ microtrend.title }}</h2>
            </v-card-title>
            <v-card-text
              v-html="microtrend.description"
            />
            <v-card-text
              v-if="video"
              class="video-player-box"
            >
              <video-player
                ref="videoPlayer"
                :options="videoOptions"
                :playsinline="true"
              />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          xs="12"
          sm="4"
        >
          <v-card
            flat
            class="microtrend-details"
          >
            <v-card-actions>
              <v-spacer/>
              <v-btn
                icon
                color="primary"
                @click="show = false"
              >
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </v-card-actions>
            <v-list>
              <v-list-item
                v-if="microtrend.initiators.length > 0"
              >
                <v-list-item-action>
                  <to-icon>producer</to-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ initiators }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="microtrend.country"
              >
                <v-list-item-action>
                  <to-icon>location</to-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ microtrend.country }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-action>
                  <to-icon>clock</to-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ date(microtrend.identifiedAt) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-card-text class="megatrends">
              <p>{{ $t('megatrends') }}</p>
              <p>
                <template v-for="megatrend in megatrends">
                  <trend-tag
                    :key="megatrend.id"
                    :color="megatrend.color"
                    :label="megatrend.title"
                  />
                </template>
              </p>
            </v-card-text>

            <v-card-text class="macrotrends">
              <p>{{ $t('macrotrends') }}</p>
              <p>
                <template v-for="macrotrend in macrotrends">
                  <trend-tag
                    v-if="macrotrend.megatrend"
                    :key="macrotrend.id"
                    :color="macrotrend.megatrend.color"
                    :label="macrotrend.title"
                  />
                </template>
              </p>
            </v-card-text>

            <v-card-text
              v-if="industries.length > 0"
              class="industries"
            >
              <p>{{ $t('industries') }}</p>
              <p>
                <trend-tag
                  v-for="(industry, idx) in industries"
                  :key="idx"
                  :label="industry"
                  color="#504F62"
                />
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      v-if="relatedMicrotrends.length > 0"
      flex
      class="microtrend-overlay microtrend-slider-container"
    >
      <h3 class="pl-9">{{ $t('relatedMicrotrends') }}</h3>
      <card-carousel
        :breakpoints="[
              [0, 1],
              [516, 2],
              [768, 3],
              [1024, 4]
            ]"
        :loaded="relatedMicrotrends.length"
        :total="relatedMicrotrends.length"
      >
        <slide
          v-for="(content, idx) in relatedMicrotrends"
          :key="content.id"
          :index="idx"
        >
          <microtrend-card
            :content="content"
            quiet
          />
        </slide>
      </card-carousel>
    </v-container>
  </v-dialog>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { Slide } from 'hooper'
import { videoPlayer } from 'vue-video-player'

import date from '../../mixins/date'
import { LOCALE, MICROTREND } from '@/store/getter-types'
import { SET_MICROTREND } from '@/store/mutation-types'

import CardCarousel from '../card-carousel'
import MicrotrendCard from './card'
import ToIcon from '../to-icon'
import TrendTag from '../trend-tag'

@Component({
  components: {
    CardCarousel,
    MicrotrendCard,
    Slide,
    ToIcon,
    TrendTag,
    videoPlayer
  },
  mixins: [
    date
  ]
})
export default class MicrotrendOverlay extends Vue {
  @Getter(LOCALE) locale
  @Getter(MICROTREND) microtrend
  @Mutation(SET_MICROTREND) setMicrotrend

  get show () {
    return this.microtrend !== null
  }

  set show (value) {
    if (!value) {
      this.setMicrotrend(null)
    }
  }

  get video () {
    return this.microtrend && this.microtrend.video
      ? this.microtrend.video
      : null
  }

  get videoOptions () {
    return {
      height: '380',
      autoplay: false,
      muted: false,
      language: 'de',
      playbackRates: [0.7, 1.0, 1.5, 2.0],
      sources: [
        {
          type: 'video/mp4',
          src: this.microtrend.video
        }
      ]
    }
  }

  get megatrends () {
    return this.microtrend && this.microtrend.megatrends
      ? this.microtrend.megatrends
      : []
  }

  get relatedMicrotrends () {
    return this.microtrend && this.microtrend.relatedMicrotrends
      ? this.microtrend.relatedMicrotrends
      : []
  }

  get macrotrends () {
    return this.microtrend && this.microtrend.macrotrends
      ? this.microtrend.macrotrends
      : []
  }

  get industries () {
    return this.microtrend && this.microtrend.industries
      ? this.microtrend.industries
      : []
  }

  get initiators () {
    return this.microtrend
      ? this.microtrend.initiators.join(', ')
      : []
  }
}
</script>

<i18n>
{
  "de": {
    "megatrends": "Mega-Trends",
    "macrotrends": "Macro-Trends",
    "industries": "Branchen",
    "relatedMicrotrends": "Verwandte Micro-Trends"
  },
  "en": {
    "megatrends": "Mega-trends",
    "macrotrends": "Macro-trends",
    "industries": "Industries",
    "relatedMicrotrends": "Related micro-trends"
  }
}
</i18n>
