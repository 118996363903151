<template>
  <v-card flat>
    <v-card-title>
      <h3>{{ $t('megatrend') }}</h3>
    </v-card-title>
    <v-card-text
      v-if="megatrend"
      class="pt-0"
    >
      <trend-tag
        :data-testid="`mt-${megatrend.id}`"
        :color="megatrend.color"
        :label="megatrend.title"
      />
    </v-card-text>

    <template v-if="megatrendMacrotrends.length > 0">
      <divider />
      <v-card-title>
        <h3>{{ $t('macrotrendsOfMegatrend') }}</h3>
      </v-card-title>
      <v-card-text class="pt-0">
        <trend-tag
          v-for="mt in megatrendMacrotrends"
          :key="mt.id"
          :data-testid="`mmt-${mt.id}`"
          :inversed="mt.id === macrotrend.id"
          :color="mt.color"
          :label="mt.title"
        />
      </v-card-text>
    </template>

    <template v-if="relatedMacrotrends.length > 0">
      <divider />
      <v-card-title>
        <h3>{{ $t('relatedMacrotrends') }}</h3>
      </v-card-title>
      <v-card-text class="pt-0">
        <trend-tag
          v-for="mt in relatedMacrotrends"
          :key="mt.id"
          :data-testid="`rmt-${mt.id}`"
          :color="mt.color"
          :label="mt.title"
        />
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'
import { MACROTRENDS } from '@/model/types'
import TrendTag from '../../trend-tag'
import Divider from '../../divider'

@Component({
  components: {
    Divider,
    TrendTag
  }
})
export default class MacrotrendTrends extends Vue {
  @Prop({
    type: Object,
    validate: obj => MACROTRENDS.includes(obj.__typename),
    required: true
  }) macrotrend

  get megatrend () {
    return this.macrotrend && this.macrotrend.megatrend
      ? this.macrotrend.megatrend
      : null
  }

  get megatrendMacrotrends () {
    return this.megatrend &&
    this.megatrend.macrotrends
      ? this.megatrend.macrotrends
      : []
  }

  get relatedMacrotrends () {
    return this.macrotrend && this.macrotrend.relatedMacrotrends
      ? this.macrotrend.relatedMacrotrends
      : []
  }
}
</script>

<i18n>
{
  "de": {
    "megatrend": "Mega-Trend",
    "macrotrendsOfMegatrend": "Macro-Trends des gleichen Mega-Trend",
    "relatedMacrotrends": "Ähnliche Macro-Trends"
  },
  "en": {
    "megatrend": "Mega-trend",
    "macrotrendsOfMegatrend": "Macro-trends of the same Mega-trend",
    "relatedMacrotrends": "Related Macro-trends"
  }
}
</i18n>
