<template>
  <v-card flat>
    <v-card-text>
      <v-textarea
        v-model="internal"
        :label="$t('label')"
        filled
        auto-grow
        rows="2"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class RatingComment extends Vue {
  @Prop({ type: String, default: () => null }) value

  set internal (value) {
    this.$emit('input', value)
  }

  get internal () {
    return this.value
  }
}
</script>

<i18n>
{
  "de": {
    "label": "Kommentar (optional)"
  },
  "en": {
    "label": "Comment (optional)"
  }
}
</i18n>
