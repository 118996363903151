<template>
  <div class="generic-tooltip">
    <v-menu
      :nudge-left="50"
      close-delay="300"
      left
      offset-y
      open-delay="100"
      open-on-hover
    >
      <template v-slot:activator="{ on }">
        <span v-on="on">{{ title }} <to-icon>info</to-icon></span>
      </template>
      <slot></slot>
    </v-menu>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
import ToIcon from '@/components/to-icon.vue'

@Component({
  components: {
    ToIcon
  }
})
export default class GenericTooltip extends Vue {
  @Prop({
    type: String,
    default: () => null
  }) title

  @Prop({
    type: Boolean,
    default: () => false
  }) hover
}
</script>
