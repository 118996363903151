<template>
  <svg :class="classes"
       :style="styles">
    <use v-bind="{'xlink:href':'#icon-tx4_'+ usedIcon.trim()}"></use>
  </svg>
</template>

<script>
export default {
  name: 'to-icon',

  props: {
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: () => null
    },
    color: {
      type: String,
      default: () => null
    }
  },

  computed: {
    usedIcon () {
      return this.icon !== null
        ? this.icon
        : this.$slots && this.$slots.default && this.$slots.default.length > 0
          ? this.$slots.default[0].text
          : 'dashboard'
    },
    styles () {
      return this.color
        ? {
          color: this.color
        }
        : {}
    },
    classes () {
      return 'tx4-svg-icon icon-tx4_' + this.usedIcon +
        (this.left ? ' icon-left' : '') +
        (this.right ? ' icon-right' : '')
    }
  }
}
</script>
