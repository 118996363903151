<template>
  <v-container
    class="microtrend-slider-container"
    fluid
  >
    <h2 class="pl-9">Micro-Trends</h2>
    <card-carousel
      :breakpoints="[[0, 1], [516, 2], [768, 3], [1024, 4], [1264, 4], [1400, 6], [1584, 6], [1896, 8], [2208, 8]]"
      :total="microtrends.length"
      :loaded="microtrends.length"
    >
      <slide
        v-for="(microtrend, idx) in microtrends"
        :key="microtrend.id"
        :index="idx"
      >
        <microtrend-card
          :content="microtrend"
        />
      </slide>
    </card-carousel>
  </v-container>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Slide } from 'hooper'
import MicrotrendCard from '../microtrend/card'
import CardCarousel from '../card-carousel'

@Component({
  components: {
    CardCarousel,
    MicrotrendCard,
    Slide
  }
})
export default class MacrotrendMicrotrends extends Vue {
  @Prop({ type: Array, default: () => [] }) microtrends
}
</script>
