<template>
  <v-card
    tile
    flat
    class="macrotrend-drivers"
  >
    <v-card-text
    >
      <v-card
        v-for="(d, idx) in drivers"
        :key="idx"
        tile
        flat
      >
        <v-list-item>
          <v-list-item-avatar>
            <to-icon
              size="40px"
            >flames</to-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle>
              {{ $t('title') }}
            </v-list-item-subtitle>
            <v-list-item-title>
              <h3>{{ d.title }}</h3>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-card-text
          class="pt-0"
          v-html="d.description"
        />
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
import ToIcon from '@/components/to-icon'
import { MACROTRENDS } from '@/model/types'

@Component({
  components: { ToIcon }
})
export default class MacrotrendDrivers extends Vue {
  @Prop({
    type: Object,
    validator: obj => MACROTRENDS.includes(obj.__typename),
    required: true
  }) macrotrend

  get drivers () {
    return this.macrotrend && this.macrotrend.drivers
      ? this.macrotrend.drivers
      : []
  }
}
</script>

<i18n>
{
  "de": {
    "title": "Treiber"
  },
  "en": {
    "title": "Driver"
  }
}
</i18n>
