<template>
  <v-chip
    class="trend-tag mr-2 mb-2"
    label
    :style="styles"
  >
    {{ label }}
  </v-chip>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class TrendTag extends Vue {
  @Prop({ type: String, required: true }) label
  @Prop({ type: String, default: () => 'primary' }) color
  @Prop({ type: Boolean, default: () => false }) inversed

  get styles () {
    return this.inversed
      ? {
        border: '1px solid ' + this.color,
        'background-color': 'white',
        color: this.color
      }
      : {
        'background-color': this.color,
        color: 'white'
      }
  }
}
</script>
