<template>
  <v-card
    class="macrotrend-challenges-and-opportunities"
    tile
    flat
  >
    <div
      v-for="(co, idx) in challengesAndOpportunities"
      :key="idx"
    >
      <v-card-title>
        <h3>{{ co.industry }}</h3>
      </v-card-title>

      <v-card-text>
        <v-card
          tile
          flat
        >
          <v-list-item>
            <v-list-item-avatar>
              <to-icon
                size="40px"
              >challenges</to-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <h3>{{ $t('challenge') }}</h3>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-card-text
            v-html="co.challenges"
          />
        </v-card>

        <v-card
          tile
          flat
        >
          <v-list-item>
            <v-list-item-avatar>
              <to-icon
                size="40px"
              >opportunities</to-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <h3>{{ $t('opportunity') }}</h3>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-card-text
            v-html="co.opportunities"
          />
        </v-card>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
import { MACROTRENDS } from '@/model/types'
import ToIcon from '@/components/to-icon'

@Component({
  components: { ToIcon }
})
export default class MacrotrendChallengesAndOpportunities extends Vue {
  @Prop({
    type: Object,
    validator: obj => MACROTRENDS.includes(obj.__typename),
    required: true
  }) macrotrend

  get challengesAndOpportunities () {
    return this.macrotrend.challengesAndOpportunities
      ? this.macrotrend.challengesAndOpportunities
      : []
  }
}
</script>

<i18n>
{
  "de": {
    "challenge": "Challenge",
    "opportunity": "Opportunity"
  },
  "en": {
    "challenge": "Challenge",
    "opportunity": "Opportunity"
  }
}
</i18n>
