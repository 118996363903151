import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/de'
import 'dayjs/locale/en'

dayjs.extend(localizedFormat)

const date = {
  computed: {
    ...mapGetters({
      locale: 'getLocale'
    })
  },

  methods: {
    date (v) {
      return v ? dayjs(v).locale(this.locale).format('ll') : ''
    },

    datetime (v) {
      return v ? dayjs(v).locale(this.locale).format('ll, HH:mm') : ''
    },

    now () {
      return dayjs().locale(this.locale).format('dddd, ll')
    },

    unix (v) {
      return dayjs(v).unix()
    }
  }
}

export default date
