<template>
  <v-card flat>
    <v-img
      height="257px"
      :src="macrotrend.image"
    />
    <v-card-text
      v-html="macrotrend.description"
    />
  </v-card>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'
import { MACROTRENDS } from '@/model/types'

@Component
export default class MacrotrendDetails extends Vue {
  @Prop({ type: Object, validate: obj => MACROTRENDS.includes(obj.__typename), required: true }) macrotrend

  get megatrend () {
    return this.macrotrend.megatrend
  }
}
</script>
