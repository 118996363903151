<template>
  <div class="card-wrap">
    <v-card
      hover
      raised
      class="microtrend"
      @click.native.stop="onClickCard"
      :style="styles"
    >
      <v-img
        :src="content.image"
        height="118"
      />
      <v-card-title>
        <div>
          <span class="caption">{{ date(content.identifiedAt) }}</span>
          <h3>{{ content.title }}</h3>
        </div>
      </v-card-title>
      <v-card-text
        class="fill-height"
        v-html="truncate(content.description, 150)"
      />
    </v-card>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { LOCALE } from '../../store/getter-types'
import { MICROTREND } from '../../model/types'
import { SET_MICROTREND } from '../../store/mutation-types'

import date from '../../mixins/date'
import text from '../../mixins/text'

@Component({
  mixins: [
    date,
    text
  ]
})
export default class MicrotrendCard extends Vue {
  @Prop({ type: Object, validate: (obj) => obj.__typename === MICROTREND, required: true }) content
  @Prop({ type: Boolean, default: () => false }) quiet
  @Getter(LOCALE) locale
  @Mutation(SET_MICROTREND) setMicrotrend

  get styles () {
    return this.quiet
      ? { cursor: 'default' }
      : { cursor: 'pointer' }
  }

  onClickCard () {
    if (!this.quiet) {
      this.setMicrotrend(this.content)
    }
  }
}
</script>
