import ellipsize from 'ellipsize'

const paragrapher = (value) => typeof value === 'string' ? '<p>' + value.replace(/(?:\r\n|\r|\n)/g, '</p><p>') + '</p>' : ''
const truncate = (value, after) => ellipsize(value, after, { chars: [' ', '&', ',', '.'], ellipse: '...' })
const abridgeText = (value) => {
  const splitted = value.split('</p>', 1)
  return splitted[0].startsWith('<p>')
    ? splitted[0] + '</p>'
    : splitted[0]
}
const conditionalParagrapher = (value) => {
  return typeof value === 'string' && value.startsWith('<p>')
    ? value
    : paragrapher(value)
}

const text = {
  methods: {
    paragrapher,
    truncate,
    abridgeText,
    conditionalParagrapher
  }
}

export default text
