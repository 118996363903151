<template>
  <v-card
    flat
    class="macrotrend-details macrotrend-details-top"
  >
    <v-card-title class="pb-3">
      <div>
        <div class="caption">
          {{ $t('title') }}
        </div>
        <h1 :style="style">
          {{ macrotrend.title }}
        </h1>
      </div>
    </v-card-title>
    <v-divider />
    <v-card-text>
      {{ $t('belongs') }}
      <span :style="style">{{ megatrend.title }}</span>
    </v-card-text>
  </v-card>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'
import { MACROTRENDS } from '../../../model/types'

@Component
export default class MacrotrendDetailsTop extends Vue {
  @Prop({
    type: Object,
    validate: obj => MACROTRENDS.includes(obj.__typename),
    required: true
  }) macrotrend

  get style () {
    return { color: this.macrotrend.megatrend.color }
  }

  get megatrend () {
    return this.macrotrend.megatrend
  }
}
</script>

<i18n>
{
  "de": {
    "title": "Macro-Trend",
    "belongs": "Dieser Trend gehört zum Mega-Trend: "
  },
  "en": {
    "title": "Macro-trend",
    "belongs": "This trend belongs to mega-trend: "
  }
}
</i18n>
