<template>
  <v-row justify="center"
         align="center">
    <div
      class="text-center ma-12"
    >
      <v-progress-circular
        indeterminate
        :size="size"
        :color="color"
      />
    </div>
  </v-row>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class LoadingCircle extends Vue {
  @Prop({ type: String | Number, default: () => 100 }) size
  @Prop({ type: String, default: () => 'primary' }) color
}
</script>
