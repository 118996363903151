<template>
  <v-card
    flat
    class="pa-2"
  >
    <v-card-title>
      <h2>{{ $t('title') }}</h2>
      <v-spacer/>
      <span class="caption float-right">{{ $t('progress') }}: {{ progress }}</span>
    </v-card-title>

    <rating-element
      v-for="ev in value"
      :key="ev.criteria.id"
      :id="ev.criteria.id"
      :criteria="ev.criteria"
      :value="ev.value"
      @input="onInput($event, ev.criteria.id)"
      :readonly="pending"
    />
  </v-card>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { PENDING, PROGRESS_FORMATTED } from '@/store/getter-types'
import RatingComment from './comment'
import RatingElement from './element'
import LoadingCircle from '../loading-circle'

@Component({
  components: {
    LoadingCircle,
    RatingComment,
    RatingElement
  }
})
export default class MacrotrendRating extends Vue {
  @Prop({
    type: Array | null,
    default: () => []
  }) value

  @Getter(PROGRESS_FORMATTED) progress

  @Getter(PENDING) pending

  onInput (value, id) {
    this.$emit('input', {
      value,
      id
    })
  }
}
</script>

<i18n>
{
  "de": {
    "title": "Trendbewertung",
    "progress": "Bewertungsfortschritt"
  },
  "en": {
    "title": "Trend evaluation",
    "progress": "Evaluation progress"
  }
}
</i18n>
