<template>
  <v-card
    flat
  >
    <v-list>
      <v-list-item>
        <v-list-item-content class="pa-2">
          <div class="slider-labeling pt-3 pb-1">
            <span class="float-left">
              <generic-tooltip
                v-if="tooltip"
                style="align-items: flex-start"
                :title="label"
              >
                <v-card
                  tile
                  width="400"
                >
                  <v-card-text
                    class="pt-5"
                    v-html="tooltip"
                  />
                </v-card>
              </generic-tooltip>
              <span v-else>{{ label }}</span>
            </span>
            <span class="float-right">
              {{ level }}
            </span>
          </div>
          <vue-slider
            v-model="internal"
            :adsorb="criteria.adsorb"
            :disabled="disabled"
            :dot-options="dotOptions"
            :marks="marks"
            :max="criteria.max"
            :min="criteria.min"
            :tooltip="'none'"
            contained
            included
            :clickable="!readonly"
            :height="2"
            :dotSize="14"
          />
        </v-list-item-content>
        <v-list-item-action
          v-if="criteria.nullable"
        >
          <v-checkbox
            class="no-info"
            v-model="disabled"
            :label="$t('noInformation')"
          />
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VueSlider from 'vue-slider-component'
import GenericTooltip from '../tooltip'

const style = {
  width: '2px',
  height: '16px',
  display: 'block',
  transform: 'translate(1px, -6px)'
}

const HUNDRED_FREE = 'hundred_free'

@Component({
  components: {
    GenericTooltip,
    VueSlider
  }
})
export default class RatingElement extends Vue {
  @Prop({ type: [String, Number] }) value

  @Prop({
    type: Object,
    required: true
  }) criteria

  @Prop({
    type: Boolean,
    default: () => false
  }) readonly

  @Prop({
    type: String,
    default: () => null
  }) id

  state = null

  get disabled () {
    return this.value === null
  }

  set disabled (value) {
    if (value) {
      this.state = this.value
      this.$emit('input', null)
    } else {
      this.$emit('input', this.state === null ? 1 : this.state)
      this.state = null
    }
  }

  get internal () {
    return this.value
  }

  set internal (value) {
    this.$emit('input', value)
  }

  get label () {
    return this.criteria.title
  }

  get tooltip () {
    return this.criteria.description
  }

  get dotOptions () {
    return [{
      disabled: this.readonly
    }]
  }

  get options () {
    return this.criteria.labels
  }

  get marks () {
    if (this.criteria.marks === 0) {
      return {}
    }
    const delta = (this.criteria.max - this.criteria.min) / (this.criteria.marks - 1)
    const result = {}
    for (let i = this.criteria.min; i <= this.criteria.max; i += delta) {
      result[i] = {
        label: '',
        style: style
      }
    }
    return result
  }

  get level () {
    return this.criteria.scale === HUNDRED_FREE
      ? this.levelAbsolute
      : this.levelIntermediate
  }

  get levelIntermediate () {
    const range = this.criteria.max - this.criteria.min
    const value = this.value - this.criteria.min
    const percent = value / range
    const idx = Math.round(percent * (this.options.length - 1))
    return this.options[idx]
  }

  get levelAbsolute () {
    return this.value === null || !this.options.length || this.options.length === 0 || this.criteria.max === 0
      ? ''
      : this.options[Math.ceil(this.value / (this.criteria.max / this.options.length)) - 1]
  }

  @Watch('id')
  onChangeId () {
    this.state = null
  }
}
</script>

<style lang="sass">
.v-list-item__action
  // width: 90px
  margin-left: 10px

  .v-label
    font-size: 14px
    padding-left: 10px
</style>

<i18n>
{
  "de": {
    "noInformation": "Keine Angabe"
  },
  "en": {
    "noInformation": "No information"
  }
}
</i18n>
