<template>
  <div :key="macrotrendId">
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="6"
          xs="12"
        >
          <macrotrend-details-top
            :macrotrend="macrotrend"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
          xs="12"
        >
          <macrotrend-details-bottom
            :macrotrend="macrotrend"
          />

          <macrotrend-trends
            :macrotrend="macrotrend"
          />

          <macrotrend-content
            :macrotrend="macrotrend"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          xs="12"
        >
          <macrotrend-rating
            v-model="internal"
          />
          <v-card
            flat
            class="pa-2"
          >
            <v-card-actions
              class="pt-10"
            >
              <v-btn
                :disabled="pending"
                color="white"
                light
                depressed
                class="primary--text"
                :to="overview"
              >
                {{ $t('back') }}
              </v-btn>
              <v-spacer/>
              <v-btn
                color="primary"
                @click="saveAndNext"
                :disabled="pending"
                :loading="pending"
                class="hidden-md-only hidden-xs-only"
              >
                {{ $t('saveAndNext') }}
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card-actions>

            <v-card-actions
              class="hidden-sm-only hidden-lg-and-up"
            >
              <v-spacer/>
              <v-btn
                :loading="pending"
                :disabled="loading || pending"
                color="primary"
                @click="saveAndNext"
              >
                {{ $t('saveAndNext') }}
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="loading">
      <loading-circle
        class="py-5"
      />
    </v-container>
    <template v-else>
      <macrotrend-microtrends
        v-if="microtrends.length > 0"
        :microtrends="microtrends"
        class="mb-5"
      />
      <div
        v-else
        style="height: 100px"
      >
        &nbsp;
      </div>
    </template>
    <microtend-overlay/>
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import {
  CHECK_EVALUATION_PARTICIPANT_VALID,
  EVALUATE_PUBLIC_EVALUATION,
  EXTEND_MACROTREND,
  START_PUBLIC_EVALUATION
} from '@/store/action-types'
import {
  CRITERIAS,
  GET_MACROTREND,
  GET_NEXT_MACROTREND,
  GET_PARTICIPANT_ID,
  IS_MACROTREND_COMPLETE,
  LOADING,
  PENDING
} from '@/store/getter-types'
import { SET_LOADING, SET_PENDING, SHOW_SNACKBAR } from '@/store/mutation-types'

import MacrotrendContent from '../components/macrotrend/content'
import MacrotrendDetailsBottom from '../components/macrotrend/details/bottom'
import MacrotrendDetailsTop from '../components/macrotrend/details/top'
import MacrotrendMicrotrends from '../components/macrotrend/microtrends'
import MacrotrendRating from '../components/rating'
import MacrotrendTrends from '../components/macrotrend/trends/trends'
import MicrotendOverlay from '../components/microtrend/overlay'
import LoadingCircle from '../components/loading-circle'

@Component({
  components: {
    LoadingCircle,
    MacrotrendContent,
    MacrotrendDetailsTop,
    MacrotrendDetailsBottom,
    MacrotrendMicrotrends,
    MacrotrendRating,
    MacrotrendTrends,
    MicrotendOverlay
  }
})
export default class EvaluateView extends Vue {
  @Prop({
    type: String,
    required: true
  }) locale

  @Prop({
    type: String,
    required: true
  }) publicEvaluationId

  @Prop({
    type: String,
    required: true
  }) megatrendId

  @Prop({
    type: String,
    required: true
  }) macrotrendId

  @Action(EVALUATE_PUBLIC_EVALUATION) evaluatePublicEvaluation
  @Action(EXTEND_MACROTREND) extendMacrotrend
  @Action(START_PUBLIC_EVALUATION) startPublicEvaluation
  @Action(CHECK_EVALUATION_PARTICIPANT_VALID) checkEvaluationParticipantValid
  @Mutation(SET_PENDING) setPending
  @Mutation(SET_LOADING) setLoading
  @Getter(GET_MACROTREND) getMacrotrend
  @Getter(IS_MACROTREND_COMPLETE) isMacrotrendComplete
  @Getter(GET_NEXT_MACROTREND) getNextMacrotrend
  @Getter(CRITERIAS) criterias
  @Getter(LOADING) loading
  @Getter(PENDING) pending
  @Getter(GET_PARTICIPANT_ID) getParticipantId
  @Mutation(SHOW_SNACKBAR) showSnackbar

  value = null

  modified = false

  get macrotrend () {
    return this.getMacrotrend(this.macrotrendId)
  }

  get microtrends () {
    return this.macrotrend && this.macrotrend.microtrends ? this.macrotrend.microtrends : []
  }

  get evaluations () {
    return this.macrotrend ? this.macrotrend.evaluations : null
  }

  get participantId () {
    return this.getParticipantId(this.publicEvaluationId)
  }

  init () {
    return this.criterias.map(elem => ({
      criteria: elem,
      value: elem.min
    }))
  }

  get overview () {
    return {
      name: 'overview',
      params: {
        locale: this.locale,
        publicEvaluationId: this.publicEvaluationId,
        megatrendId: this.megatrendId
      }
    }
  }

  async saveAndNext () {
    try {
      await this.checkEvaluationParticipantValid(this.publicEvaluationId)

      if (!this.participantId) {
        await this.startPublicEvaluation(this.publicEvaluationId)
      }

      await this.evaluatePublicEvaluation({
        publicEvaluationId: this.publicEvaluationId,
        macrotrendId: this.macrotrendId,
        values: this.value
      })
      this.showSnackbar({
        message: this.$t('success'),
        color: 'success',
        timeout: 2500
      })

      const mt = this.getNextMacrotrend(this.macrotrendId)
      if (mt !== null) {
        this.$router.push({
          name: 'evaluate',
          params: {
            megatrendId: mt.megatrend.id,
            macrotrendId: mt.id,
            publicEvaluationId: this.publicEvaluationId,
            locale: this.locale
          }
        })
      } else {
        this.$router.push({
          name: 'overview',
          params: {
            publicEvaluationId: this.publicEvaluationId,
            locale: this.locale
          }
        })
      }
    } catch (error) {
      console.log('%cError on saveAndNext: ', 'color:red', error) // eslint-disable-line no-console
      this.showSnackbar({
        message: this.$t('error'),
        color: 'error'
      })
      this.setLoading(false)
      this.setPending(false)
    }
  }

  set internal (v) {
    const elem = this.value.find(elem => elem.criteria.id === v.id)
    if (elem) {
      elem.value = v.value
      this.modified = true
    }
  }

  get internal () {
    return this.value
  }

  async setup () {
    this.value = this.evaluations
      ? this.evaluations
      : this.init()

    if (!this.isMacrotrendComplete(this.macrotrendId)) {
      await this.extendMacrotrend({
        publicEvaluationId: this.publicEvaluationId,
        macrotrendId: this.macrotrendId
      })
    }
  }

  @Watch('macrotrendId')
  async onChangeMacrotrendId (value) {
    await this.setup()
  }

  async mounted () {
    await this.setup()
  }
}
</script>

<i18n>
{
  "de": {
    "success": "Ihre Bewertung wurde erfolgreich gespeichert.",
    "error": "Ihre Bewertung konnten nicht gespeichert werden, nehmen Sie bitte mit uns Kontakt auf.",
    "back": "Zurück zur Übersicht",
    "saveAndNext": "Speichern und weiter"
  },
  "en": {
    "success": "Your evaluation was saved successfully.",
    "error": "Your evaluation could not be saved, please contact us.",
    "back": "Back to overview",
    "saveAndNext": "Save and next"
  }
}
</i18n>
