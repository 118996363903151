<template>
  <div v-if="availableTabs.length > 0">
    <divider/>

    <template v-if="availableTabs.length === 1">
      <component
        :is="availableTabs[0].component"
        :macrotrend="macrotrend"
      />
    </template>
    <template
      v-else
    >
      <v-tabs
        v-model="currentTab"
        class="dark"
        grow
      >
        <v-tab
          v-for="({ label }, idx) in availableTabs"
          :key="idx"
        >
          {{ label }}
        </v-tab>

        <v-tab-item
          v-for="({ component }, idx) in availableTabs"
          :key="idx"
        >
          <component
            :is="component"
            :macrotrend="macrotrend"
          />
        </v-tab-item>
      </v-tabs>
    </template>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
import { MACROTRENDS } from '@/model/types'

import Divider from '@/components/divider'
import MacrotrendChallengesAndOpportunities from './challenges-and-opportunities'
import MacrotrendDrivers from './drivers'

const CHALLENGES_AND_OPPORTUNITIES = 'challengesAndOpportunities'
const DRIVERS = 'drivers'

@Component({
  components: {
    Divider,
    MacrotrendChallengesAndOpportunities,
    MacrotrendDrivers
  }
})
export default class MacrotrendTabbedContent extends Vue {
  @Prop({
    type: Object,
    validator: obj => MACROTRENDS.includes(obj.__typename)
  }) macrotrend

  currentTab = 0

  get hasChallengesAndOpportunities () {
    return this.macrotrend && this.macrotrend.challengesAndOpportunities
      ? this.macrotrend.challengesAndOpportunities.length > 0
      : false
  }

  get hasDrivers () {
    return this.macrotrend && this.macrotrend.drivers
      ? this.macrotrend.drivers.length > 0
      : false
  }

  get availableTabs () {
    return [
      this.hasDrivers
        ? {
          label: this.label(DRIVERS),
          component: MacrotrendDrivers
        }
        : null,
      this.hasChallengesAndOpportunities
        ? {
          label: this.label(CHALLENGES_AND_OPPORTUNITIES),
          component: MacrotrendChallengesAndOpportunities
        }
        : null
    ].filter(elem => elem !== null)
  }

  label (type) {
    return this.$t(type)
  }
}
</script>

<i18n>
{
  "de": {
    "challengesAndOpportunities": "Challenges & Opportunities",
    "drivers": "Treiber",
    "mainstreamAdoption": "Reifegrad",
    "threads": "Beiträge"
  },
  "en": {
    "challengesAndOpportunities": "Challenges & Opportunities",
    "drivers": "Drivers",
    "mainstreamAdoption": "Mainstream Adoption",
    "threads": "Posts"
  }
}
</i18n>
