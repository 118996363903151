<template>
  <hooper
    :ref="uid"
    :settings="options"
    @slide="onSlide"
  >
    <slot>
      <loading-circle />
    </slot>
    <hooper-navigation
      v-if="loaded > 0"
      slot="hooper-addons"
    />
  </hooper>
</template>

<script>
import {
  Hooper,
  Navigation as HooperNavigation
} from 'hooper'
import LoadingCircle from './loading-circle'

export default {
  name: 'card-carousel',

  components: {
    LoadingCircle,
    Hooper,
    HooperNavigation
  },

  props: {
    breakpoints: {
      type: Array,
      default: () => [[0, 1], [516, 2], [768, 3], [1024, 4], [1264, 4], [1400, 6], [1584, 6], [1896, 8], [2208, 8]]
    },

    total: {
      type: Number,
      default: -1
    },

    loaded: {
      type: Number,
      default: 0
    },

    active: {
      type: Boolean,
      default: () => false
    }
  },

  watch: {
    active (newActive) {
      if (newActive) {
        this.$nextTick().then(() => {
          this.carousel.restart()
          this.carousel.currentSlide = 0
        }).then(() => {
          this.carousel.slideTo(0)
        })
      }
    }
  },

  methods: {
    onSlide (evt) {
      this.currentSlide = evt.currentSlide
      if (this.total > 0 && this.currentSlide + this.delta > this.loaded && this.currentSlide) {
        this.$emit('fetchMore')
      }
    }
  },

  computed: {
    carousel () {
      return this.$refs[this.uid]
    },

    delta () {
      return this.carousel.config.itemsToShow * 2
    },

    brs () {
      const result = {}
      this.breakpoints.forEach(elem => {
        const w = elem[0]
        const s = elem[1]
        result[w] = {
          itemsToShow: s,
          itemsToSlide: s
        }
      })
      return result
    },

    options () {
      return {
        mouseDrag: false,
        touchDrag: true,
        wheelControl: false,
        keysControl: false,
        shortDrag: false,
        centerMode: false,
        itemsToShow: 4,
        itemsToSlide: 4,
        breakpoints: this.brs
      }
    }
  },

  updated () {
    this.carousel.updateWidth()
  },

  beforeMount () {
    this.uid = 'carousel' + Math.random().toString(36).substr(2, 9)
  }
}
</script>
